import { lazy, Suspense } from "react";

const Content = lazy(() => import("./Content"));

function App() {
  return (
    <div>
      <Suspense
        fallback={
          <section className="loading">
            <section className="loader">
              <section></section>
              <section></section>
            </section>
          </section>
        }
      >
        <Content />
      </Suspense>
    </div>
  );
}

export default App;
